
export const getParameterByName = (name: string, url: string) => {
    if (!url) url = window.location.href
    name = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const isInWhitelist = (url: string) => {
    if (!url) return false
    let hostname = ''

    try {
        hostname = new URL(url).hostname
    } catch (e) {
        return false
    }

    if (hostname.endsWith('4dem.it')) return true
    if (hostname.endsWith('.dev.local')) return true

    return false
}
